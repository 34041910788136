@tailwind base;
@tailwind components;
@tailwind utilities;


.hero-section {
    background-image: url('../public/mobile-bg.png');
   
  }
  
  @media (min-width: 930px) {
    .hero-section {
      background-image: url('../public/background.png');
    }
  }


  
  .loader {
    /* border: 2px solid red; */
    width: 30px;
    height:30px;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,white 94%,#0000) top/2px 2px no-repeat,
      conic-gradient(#0000 30%,white);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 6px),#000 0);
    animation:s3 1s infinite linear;
  }
  
  @keyframes s3{ 
    100%{transform: rotate(1turn)}
  }